export const colors = {
  blue: {
    50: '#FAFCFC',
    100: '#EBF1F4',
    200: '#E1EAEF',
    300: '#CDDDE4',
    400: '#196185',
    500: '#287FB8', // originally from Couchsurfing's MUI extension: #05537A
    600: '#2371A3', // originally from Couchsurfing's MUI extension: #054E73
    700: '#044666',
    800: '#043F5D',
    900: '#033853',
  },
  orange: {
    50: '#FFFCFA',
    100: '#FEF2EB',
    200: '#FEEBE2',
    300: '#FDDECE',
    400: '#F6651E',
    500: '#F5580A',
    600: '#E65309',
    700: '#CE4A08',
    800: '#BA4308',
    900: '#A73C07',
  },
  grey: {
    50: '#FDFDFD',
    100: '#F8F8F8',
    200: '#F5F5F5',
    300: '#EEEEEE',
    400: '#AFAFAF',
    500: '#A8A8A8',
    600: '#9E9E9E',
    700: '#8D8D8D',
    800: '#808080',
    900: '#727272',
  },
  red: {
    50: '#FEFAFA',
    100: '#FCEBEB',
    200: '#FAE0E0',
    300: '#F7CCCC',
    400: '#D81414',
    500: '#D50000',
    600: '#C80000',
    700: '#B30000',
    800: '#A20000',
    900: '#910000',
  },
  green: {
    50: '#FAFEFC',
    100: '#EBFBF1',
    200: '#E0F8EA',
    300: '#CCF4DD',
    400: '#14CC61',
    500: '#00C853',
    600: '#00BC4E',
    700: '#00A846',
    800: '#00983F',
    900: '#008838',
  },
  yellow: {
    50: '#FFFDFA',
    100: '#FFFAF0',
    200: '#FFF5E0',
    300: '#FFEECC',
    400: '#FFB214',
    500: '#FFAB00',
    600: '#F0A100',
    700: '#D69000',
    800: '#AD7400',
    900: '#AD7400',
  },
}
