'use client'

import { Link } from '@mui/material'
import { type PropsWithChildren } from 'react'

import { useAnalytics } from '@/modules/analytics/hooks/use-analytics'
import { useModalDispatch } from '@/modules/ui/utils/modal-dispatch'

import classes from './reactivate-link.module.scss'

export function ReactivateLink({ children }: PropsWithChildren) {
  const open = useModalDispatch()
  const { logEvent } = useAnalytics()

  function handleClick() {
    open('login', { isReactivation: true })
    logEvent('login_select', { source: 'reactivate_flash_message' })
  }

  return (
    <Link className={classes.reactivateLink} onClick={handleClick}>
      {children}
    </Link>
  )
}
