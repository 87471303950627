// See https://github.com/trpc/trpc/discussions/2036#discussioncomment-4800324

import { type TRPCLink } from '@trpc/client'
import { observable } from '@trpc/server/observable'
import { useSetAtom } from 'jotai'
import { useRouter } from 'next/navigation'
import { useTranslations } from 'next-intl'
import { useCallback } from 'react'

import { ROUTES } from '@/config/routes'
import { ReactivateLink } from '@/modules/auth/components/reactivate-link'
import { mainAlertAtom } from '@/modules/ui/components/main-alert/atoms'

import { isHammerTimeError, HAMMERTIME_API_ERRORS } from '../../hammer-time/lib/errors'
import { type TRPCRouter } from '../router'

// eslint-disable-next-line func-style
export function useGlobalClientErrorLink(): TRPCLink<TRPCRouter> {
  const setMainAlert = useSetAtom(mainAlertAtom)
  const t = useTranslations()
  const router = useRouter()

  // See https://trpc.io/docs/client/links#example
  return useCallback(() => {
    return ({ next, op }) => {
      // this is when passing the result to the next link
      // each link needs to return an observable which propagates results
      return observable((observer) => {
        return next(op).subscribe({
          next(value) {
            observer.next(value)
          },
          error(err) {
            observer.error(err)
            if (isHammerTimeError(err.shape?.data)) {
              const hammertimeErrors = err.shape.data.hammertimeError

              const deactivatedError = hammertimeErrors?.find(
                ({ code }) => code === HAMMERTIME_API_ERRORS.SESSION.USER_DEACTIVATED
              )

              if (deactivatedError) {
                setMainAlert({
                  isAutoCloseDisabled: true,
                  severity: 'error',
                  message: deactivatedError.can_reactivate
                    ? t.rich('auth.reactivate.prompt', {
                        reactivateLink: (chunks) => <ReactivateLink>{chunks}</ReactivateLink>,
                      })
                    : t('auth.reactivate.adminPrompt'),
                })
              }

              if (
                hammertimeErrors?.some(({ code }) =>
                  [
                    HAMMERTIME_API_ERRORS.SESSION.INVALID_SIGNATURE,
                    HAMMERTIME_API_ERRORS.SESSION.INVALID_TOKEN,
                  ].includes(code)
                )
              ) {
                router.push(ROUTES.HOME)
              }
            }
          },
          complete() {
            observer.complete()
          },
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
