'use client'

import { QueryClientProvider, type QueryClient } from '@tanstack/react-query'
import { httpBatchLink } from '@trpc/client'
import React from 'react'
import superjson from 'superjson'

import { IS_DEVELOPMENT } from '@/config'
import { env } from '@/config/env'
import { ROUTES } from '@/config/routes'

import { makeQueryClient } from './query-client'
import { useGlobalClientErrorLink } from './use-global-client-error-link'
import { trpc } from '../lib/browser'

let clientQueryClientSingleton: QueryClient | undefined

function getQueryClient() {
  if (typeof window === 'undefined') {
    // Server: always make a new query client
    return makeQueryClient()
  }

  // Browser: use singleton pattern to keep the same query client
  return (clientQueryClientSingleton ??= makeQueryClient())
}

function getUrl() {
  let base = ''

  if (typeof window === 'undefined') {
    base = IS_DEVELOPMENT ? 'http://localhost:3000' : env('NEXT_PUBLIC_BASE_WEB_URL')
  }

  return `${base}${ROUTES.TRPC}`
}

export function TRPCProvider({ children }: React.PropsWithChildren) {
  const queryClient = getQueryClient()
  const errorLink = useGlobalClientErrorLink()

  const [trpcClient] = React.useState(() =>
    trpc.createClient({
      links: [
        errorLink,
        httpBatchLink({
          transformer: superjson,
          url: getUrl(),
        }),
      ],
    })
  )

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  )
}
