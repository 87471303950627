'use client'

import { usePathname } from 'next/navigation'
import { useEffect } from 'react'

// Workaround for https://github.com/vercel/next.js/issues/58924
// TLDR There is a bug in Next.js where the page view event is not triggered on client navigation
export function PageViewTrigger() {
  const pathname = usePathname()

  useEffect(() => {
    if (!window.gtag) return

    // We are skipping the first page view event because it is already sent by the Google Tag Manager script
    if (window.gtagInitialLoad) {
      window.gtagInitialLoad = false

      return
    }

    window.gtag('event', 'page_view', {
      page_location: pathname,
    })
  }, [pathname])

  return null
}
