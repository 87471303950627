'use client'

import { useSetAtom } from 'jotai'
import { useEffect } from 'react'

import { trpc } from '@/modules/api/trpc/lib/browser'
import { mainAlertAtom } from '@/modules/ui/components/main-alert/atoms'

export function FlashMessageTrigger() {
  const utils = trpc.useUtils()
  const setMainAlert = useSetAtom(mainAlertAtom)
  const signUpDataQuery = trpc.session.signUpData.useQuery()
  const updateSignUpDataMutation = trpc.session.updateSignUpData.useMutation({
    onSettled: async () => {
      await utils.session.signUpData.invalidate()
    },
  })

  useEffect(() => {
    if (signUpDataQuery.data?.flash_message) {
      setMainAlert({
        severity: 'info',
        message: signUpDataQuery.data.flash_message,
        onClose: () => {
          updateSignUpDataMutation.mutate({
            flash_message: undefined,
          })
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signUpDataQuery.data])

  return null
}
