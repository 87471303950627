import { pxToRem } from '@/modules/utils/lib/conversions'

export const typography = {
  fontFamily: 'var(--font-lato)',
  h1: { fontSize: pxToRem(44), fontWeight: 500, lineHeight: pxToRem(48) },
  h2: { fontSize: pxToRem(28), fontWeight: 500, lineHeight: pxToRem(32) },
  h3: { fontSize: pxToRem(20), fontWeight: 500, lineHeight: pxToRem(24) },
  h4: { fontSize: pxToRem(18), fontWeight: 600, lineHeight: pxToRem(20) },
  h5: { fontSize: pxToRem(18), fontWeight: 500, lineHeight: pxToRem(20) },
  h6: { fontSize: pxToRem(16), fontWeight: 500, lineHeight: pxToRem(20) },
  subtitle1: { fontSize: pxToRem(16), fontWeight: 400, lineHeight: pxToRem(24) },
  subtitle2: { fontSize: pxToRem(16), fontWeight: 500, lineHeight: pxToRem(24) },
  body1: { fontSize: pxToRem(16), fontWeight: 400, lineHeight: pxToRem(20) },
  body2: { fontSize: pxToRem(14), fontWeight: 400, lineHeight: pxToRem(20) },
  button: { fontSize: pxToRem(16), fontWeight: 400, lineHeight: pxToRem(20), textTransform: 'none' },
  caption: { fontSize: pxToRem(14), fontWeight: 400, lineHeight: pxToRem(16) },
  overline: { fontSize: pxToRem(12), fontWeight: 400, lineHeight: pxToRem(16) },
} as const
